<template>
  <el-dialog
    v-model="dialogVisible"
    title="人脸识别认证"
    :close-on-click-modal="false"
    width="550px"
  >
    <div class="facebox">
      <div
        class="codebox pt-r w-178 h-178 dp-fc"
        @click="state.isScanning = !state.isScanning"
      >
        <div class="imgbox w-168 h-168 pt-r">
          <img :src="state.code" class="w100 h100" />
          <div
            class="templatebox w100 h100 dp-f fw-w jc-c pt-a ai-c ac-c"
            v-if="state.isScanning"
          >
            <img
              src="@/assets/img/realname/successfully.png"
              class="w-44 h-44 mb-16"
            />
            <div class="w100 ta-c mb-10 successfully">扫码成功</div>
            <div class="w100 ta-c fs-14">请在手机端继续操作</div>
          </div>
        </div>
        <div class="pt-a triangle"></div>
      </div>
      <template v-if="state.isScanning"> </template>
      <template v-else>
        <div class="w100 ta-c reflash cu-p" @click="reflash">刷新二维码</div>
        <div class="w100 ta-c mb-10">打开微信扫一扫</div>
        <div class="w100 ta-c">按照提示要求完成人脸识别</div>
      </template>
    </div>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits,onUnmounted } from "vue";
import { httpToken } from "@/utils/request";
import { handleMessage } from "@/utils/server/confirm.js";
import qs from "qs";
const dialogVisible = ref(false); //弹框显示
const emit = defineEmits(["setface"]);
const state = reactive({
  isScanning: false, //是否扫码
  code: "",
  information: {},
  timmer: null,
});
const show = (data) => {
  dialogVisible.value = true;
    window.clearInterval(state.timmer); //清除定时器
  state.timmer = null;
  let json = {
    name: data.name,
    idCard: data.idNumber,
  };
  state.information = json;
  httpToken({
    method: "post",
    url: "/admin/certification/personalFace",
    data: json,
  }).then((res) => {
    state.code = res.data;
    //
    state.timmer = setInterval(() => {
      httpToken({
        method: "post",
        url: "/admin/certification/personalFaceForResult",
      }).then((res) => {
        if (res.data == 1) {
          window.clearInterval(state.timmer); //清除定时器
          state.timmer = null;
          handleMessage("认证成功", "success");
          emit('setface')
        }
      });
    }, 1000);
    //
  });
};

const reflash = () => {
  window.clearInterval(state.timmer); //清除定时器
  state.timmer = null;
  httpToken({
    method: "post",
    url: "/admin/certification/personalFace",
    data: state.information,
  }).then((res) => {
    console.log(res.data);
    state.code = res.data;
    state.isScanning = false;
        state.timmer = setInterval(() => {
      httpToken({
        method: "post",
        url: "/admin/certification/personalFaceForResult",
      }).then((res) => {
        if (res.data == 1) {
          window.clearInterval(state.timmer); //清除定时器
          state.timmer = null;
          handleMessage("认证成功", "success");
          emit('setface')
        }
      });
    }, 1000);
    handleMessage("刷新成功", "success");
  });
};
// 扫描成功跳转下一页

onUnmounted(() => {
  window.clearInterval(state.timmer); //清除定时器
  state.timmer = null;
});


defineExpose({
  show,
});
</script>
<style lang="scss" scoped >
.facebox {
  min-height: 450px;
  padding-top: 70px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .reflash {
    color: #276ff9;
  }

  .codebox {
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 4px 8px -5px rgba(0, 0, 0, 0.12),
      0px 8px 21px 0px rgba(0, 0, 0, 0.08),
      0px 12px 37px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;

    .imgbox {
      .templatebox {
        top: 0;
        background: rgba(255, 255, 255, 0.9);

        .successfully {
          font-weight: 500;
          color: #2cb354;
        }
      }
    }
  }
}

.triangle {
  bottom: -5px;
  width: 0;
  height: 0;
  border-top: 5px solid rgb(255, 255, 255);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
</style>